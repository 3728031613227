<template>
  <b-button
    :variant="text ? 'outline-primary' : 'primary'"
    size="sm"
    :class="{'btn-icon': !text}"
    :title="title"
    @click="$emit('click')"
    @dblclick="$emit('dblclick')"
  >
    <feather-icon
      :size="!text ? '17' : '14'"
      icon="PlusIcon"
      :class="{'mr-50': text}"
    />
    <span v-if="text">{{ text }}</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'SidepanelAddButton',
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
