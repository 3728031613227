<template>
  <div
    style="flex-basis: 50%"
    class="mb-1"
  >
    <template v-if="inline">
      <div class="d-flex">
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="$emit('click')"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Добавить карточку
        </b-button>
      </div>
    </template>
    <template v-else>
      <b-card
        no-body
        border-variant="secondary"
        class="course-card full-height mb-0 cursor-pointer d-flex flex-column align-items-center justify-content-center"
        @click="$emit('click')"
      >
        <feather-icon
          class="plus-icon"
          icon="PlusIcon"
          size="35"
        />
        <span class="font-small-3 text-center px-1 user-select-none">
          Добавить карточку
        </span>
      </b-card>
    </template>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'SidepanelLessonStepAddCard',
  components: {
    BCard,
    BButton,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.course-card {
  &:hover .plus-icon {
    transform: scale(1.2);
  }
}
.plus-icon {
  transition: 0.2s ease-in-out;
}
</style>
