<template>
  <div
    style="flex-basis: 50%"
    class="mb-1"
  >
    <template v-if="inline">
      <a
        class="mb-1 mx-50 d-block cursor-pointer"
        :class="{'text-primary': selected}"
        @click="stepSelected"
      >
        {{ index + 1 }}. {{ stepData.name || 'Без названия' }}
      </a>
    </template>
    <template v-else>
      <b-card
        no-body
        :border-variant="selected ? 'primary' : 'secondary'"
        class="course-card full-height mb-0 cursor-pointer"
        @click="stepSelected"
      >
        <div class="p-50">
          <p class="font-small-3 mb-0 font-weight-bolder">
            {{ index + 1 }}. {{ stepData.name || 'Без названия' }}
          </p>
        </div>
        <store-image
          v-if="image"
          :store-id="image"
        />
        <b-card-body
          v-else-if="text"
          class="p-50 font-small-2 mb-0 font-weight-bolder"
        />
        <!--     v-html="text" если что-то отъедет    -->
      </b-card>
    </template>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import { getStepElementByType } from '@/helpers'

export default {
  name: 'SidepanelLessonStep',
  components: {
    StoreImage,
    BCard,
    BCardBody,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    stepSelected: {
      type: Function,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    elements() {
      if (this.stepData.home_tasks_id) return this.stepData.homeTasksStepElements
      return this.stepData.stepElements
    },
    image() {
      return getStepElementByType(this.elements, 'image')
    },
    text() {
      return getStepElementByType(this.elements, 'text')
    },
    stepData() {
      if (this.step.step_id) return this.step.step
      return this.step
    },
  },
}
</script>

<style scoped>

</style>
