<template>
  <b-card
    no-body
    class="shadow-none mb-0 bg-transparent"
  >
    <div v-if="loading">
      <skeletor
        v-for="(skeleton, key) in 9"
        :key="key"
        width="100%"
        height="50"
        class="mb-1"
      />
    </div>
    <div
      v-else-if="!lessons.length"
      class="d-flex flex-column align-items-center justify-content-center mt-2"
    >
      <p class="font-medium-3">
        Уроков нет
      </p>
    </div>
    <div v-else>
      <div
        v-for="(lesson, key) in lessons"
        :key="lesson.id"
        class="d-flex align-items-start"
      >
        <sidepanel-lesson
          class="flex-fill"
          :course="course"
          :lesson="lesson"
          :editable="editable"
          :selected-step="selectedStep"
          :inline="inline"
          :assigned="assigned"
          @updated="lsn => $set(lessons, key, {...lsn})"
          @deleted="$emit('deleted')"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import SidepanelLesson from '@/components/stream-courses/course/sidepanel/lesson/SidepanelLesson.vue'

export default {
  name: 'SidepanelLessons',
  components: {
    SidepanelLesson,
    BCard,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    lessons: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectedStep: {
      type: Object,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    assigned: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
