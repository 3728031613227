<template>
  <b-card
    no-body
    class="bg-transparent shadow-none"
  >
    <div
      v-if="loading"
      class="d-flex flex-wrap"
      style="margin-left: -0.5rem; margin-right: -0.5rem"
    >
      <div
        v-for="(skeleton, key) in 6"
        :key="key"
        style="flex-basis: 50%; padding-right: 0.5rem; padding-left: 0.5rem"
        class="mb-1"
      >
        <skeletor
          width="100%"
          height="150"
        />
      </div>
    </div>
    <div
      v-else-if="!themes.length"
      class="d-flex flex-column align-items-center justify-content-center mt-2"
    >
      <p class="font-medium-3">
        В курсе нет тем
      </p>

      <create-theme-modal
        v-if="editable"
        :course="course"
        @created="$emit('created')"
      >
        <template #activator="{show}">
          <sidepanel-add-button
            text="Добавить тему"
            @click="show"
          />
        </template>
      </create-theme-modal>
    </div>
    <div
      v-else
      class="d-flex flex-wrap"
      style="margin-left: -0.5rem; margin-right: -0.5rem"
    >
      <div
        v-for="(theme, key) in themes"
        :key="theme.id"
        style="flex-basis: 50%"
        class="mb-1"
      >
        <side-panel-theme
          :theme="theme"
          :editable="editable"
          @updated="th => $set(themes, key, { ...th })"
          @deleted="$emit('deleted', theme)"
          @click="$emit('themeSelected', theme)"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import SidepanelAddButton from '@/components/stream-courses/course/sidepanel/header/SidepanelAddButton.vue'
import SidePanelTheme from '@/components/stream-courses/course/sidepanel/theme/SidePanelTheme.vue'
import CreateThemeModal from '@/components/new_courses/course/theme/CreateThemeModal.vue'

export default {
  name: 'SidepanelThemes',
  components: {
    CreateThemeModal,
    SidePanelTheme,
    SidepanelAddButton,
    BCard,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    themes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
