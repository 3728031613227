<template>
  <b-row class="course-themes">
    <b-col
      v-if="!editorExpanded"
      cols="12"
      :md="themes.length ? 6 : 12"
      :xl="themes.length ? 4 : 12"
    >
      <b-card
        no-body
        class="mb-0 sidepanel-courses"
      >
        <div
          class="d-flex align-items-center"
          style="min-height: 47px"
        >
          <sidepanel-breadcrumbs
            v-if="themes.length"
            no-course-crumb
            :course="course"
            :theme="selectedTheme"
            :remove-course="() => { selectedTheme = null; }"
            :remove-theme="clearTheme"
            :editable="editable"
          />
          <div class="flex-fill" />
          <div
            v-if="editable"
            class="mx-1 mt-1"
          >
            <create-theme-modal
              v-if="!selectedTheme && themes.length"
              :course="course"
              @created="getThemes"
            >
              <template #activator="{show}">
                <sidepanel-add-button
                  text="Добавить тему"
                  @click="show"
                />
              </template>
            </create-theme-modal>

            <create-lesson-modal
              v-else-if="selectedTheme"
              :theme="selectedTheme"
              @created="getLessons"
            >
              <template #activator="{show}">
                <sidepanel-add-button
                  text="Добавить урок"
                  @click="show"
                />
              </template>
            </create-lesson-modal>
          </div>
        </div>

        <div class="list-container">
          <sidepanel-themes
            v-if="!selectedTheme"
            :course="course"
            :themes="themes"
            :loading="loadingThemes"
            :editable="editable"
            @created="getThemes"
            @themeSelected="selectTheme"
            @deleted="deleteTheme"
          />

          <sidepanel-lessons
            v-else
            :course="course"
            :lessons="lessons"
            :loading="loadingLessons"
            :editable="editable"
            :selected-step="selectedStep"
            inline
            @deleted="getLessons"
          />
        </div>
      </b-card>
    </b-col>
    <b-col
      v-if="themes.length"
      :key="stepKey"
      cols="12"
      :md="editorExpanded ? 12 : 6"
      :xl="editorExpanded ? 12 : 8"
    >
      <step-editor
        :step="selectedStep"
        :expanded="editorExpanded"
        :editable="editable"
        expandable
        @toggleExpand="() => editorExpanded =! editorExpanded"
        @deleted="stepDeleted"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import SidepanelBreadcrumbs from '@/components/stream-courses/course/sidepanel/SidepanelBreadcrumbs.vue'
import SidepanelThemes from '@/components/stream-courses/course/sidepanel/SidepanelThemes.vue'
import SidepanelLessons from '@/components/stream-courses/course/sidepanel/SidepanelLessons.vue'
import StepEditor from '@/components/new_courses/course/theme/lesson/step/StepEditor.vue'
import SidepanelAddButton from '@/components/stream-courses/course/sidepanel/header/SidepanelAddButton.vue'
import CreateThemeModal from '@/components/new_courses/course/theme/CreateThemeModal.vue'
import CreateLessonModal from '@/components/new_courses/course/theme/lesson/CreateLessonModal.vue'

export default {
  name: 'CourseThemes',
  components: {
    CreateLessonModal,
    CreateThemeModal,
    SidepanelAddButton,
    StepEditor,
    SidepanelLessons,
    SidepanelThemes,
    SidepanelBreadcrumbs,
    BCard,
    BRow,
    BCol,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingThemes: false,
    loadingLessons: false,
    selectedStep: null,
    selectedType: null,
    themes: [],
    lessons: [],
    selectedTheme: null,
    editorExpanded: false,
    stepKey: 0,
  }),
  beforeDestroy() {
    this.$eventBus.$off('select-step')
    // window.removeEventListener('popstate', () => {})
  },
  async beforeMount() {
    this.$eventBus.$on('select-step', step => {
      if (this.selectedStep && this.selectedStep.id === step.id) this.selectedStep = null
      else this.selectedStep = { ...step }
      this.stepKey += 1
    })
    // window.addEventListener('popstate', this.handleBackButton, false)
  },
  methods: {
    // handleBackButton() {
    //   if (this.selectedTheme) {
    //     this.selectedTheme = null
    //     window.location.hash = ''
    //   }
    //   if (window.location.hash.includes('theme')) {
    //     const themeId = parseInt(window.location.hash.split('-')[1], 10)
    //     if (!this.selectedTheme || (this.selectedTheme && this.selectedTheme.id !== themeId)) {
    //       const theme = this.themes.filter(_theme => _theme.id === themeId)[0]
    //       if (theme) this.selectTheme(theme)
    //     }
    //   }
    //   this.$eventBus.$emit('page-title', this.course.name)
    //   this.clearStep()
    // },
    async getThemes() {
      this.loadingThemes = true
      const { items } = await this.$http.get(`/themes/index?course_id=${this.course.id}`)
      this.themes = items
      this.loadingThemes = false
    },
    async getLessons() {
      this.loadingLessons = true
      const { items } = await this.$http.get(`/lessons/index?theme_id=${this.selectedTheme.id}`)
      this.lessons = items
      this.loadingLessons = false
    },
    selectTheme(theme) {
      this.lessons = []
      // window.location.hash = `#theme-${theme.id}`
      this.selectedTheme = theme
      this.getLessons()
      this.clearStep()
    },
    deleteTheme() {
      this.getThemes()
    },
    clearTheme() {
      // window.location.hash = ''
      this.selectedTheme = null
      this.getThemes()
      this.clearStep()
    },
    clearStep() {
      this.selectedStep = null
    },
    stepDeleted(step) {
      this.clearStep()
      this.$eventBus.$emit(`lesson-${step.lesson_id}-step-removed`)
    },
  },
}
</script>

<style scoped>

</style>
