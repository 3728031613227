<template>
  <b-card
    no-body
    border-variant="secondary"
    class="course-card full-height mb-0 cursor-pointer"
    @mouseenter="showOverlay = true"
    @mouseleave="showOverlay = false"
    @click="$emit('click')"
  >
    <create-theme-modal
      :theme="theme"
      @updated="th => $emit('updated', th)"
    >
      <template #activator="{show}">
        <b-overlay
          :show="editable && showOverlay"
          class="zindex-1"
          rounded
          no-wrap
          no-center
        >
          <template #overlay>
            <div class="d-flex flex-column">
              <div class="d-flex align-items-start justify-content-end p-50">
                <b-button
                  v-if="canEdit"
                  class="btn-icon"
                  variant="flat-secondary"
                  size="sm"
                  title="Изменить"
                  @click.stop="show"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  v-if="canDelete"
                  class="btn-icon"
                  variant="flat-danger"
                  size="sm"
                  title="Удалить"
                  @click.stop="deleteTheme"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </div>
              <div class="d-flex align-items-center justify-content-center flex-fill">
                Показать уроки
              </div>
            </div>
          </template>
        </b-overlay>
      </template>
    </create-theme-modal>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Тема ${theme.name} и все связанные с ней уроки будут удалены`"
    />

    <b-card-body class="p-1">
      <p class="font-medium-1 mb-0 font-weight-bold">
        {{ theme.name }}
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BOverlay, BCardBody, BButton,
} from 'bootstrap-vue'
import CreateThemeModal from '@/components/new_courses/course/theme/CreateThemeModal.vue'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'

export default {
  name: 'SidePanelTheme',
  components: {
    ConfirmModal,
    CreateThemeModal,
    BCard,
    BOverlay,
    BCardBody,
    BButton,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showOverlay: false,
  }),
  computed: {
    canEdit() {
      return this.$can('edit', 'courses')
    },
    canDelete() {
      return this.$can('delete', 'courses')
    },
  },
  methods: {
    deleteTheme() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/themes/delete?id=${this.theme.id}`)
              .then(() => {
                this.$emit('deleted', this.theme)
              })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
