<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form @submit.prevent="saveTheme">

            <div class="p-2">
              <!-- name -->
              <b-form-group
                label-for="name"
                :label="$t('page.course.theme.labels.name')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    name="name"
                    :placeholder="$t('page.course.theme.labels.name')"
                    :state="errors.length > 0 ? false:null"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- submit button -->
            <div class="thin-divider" />
            <div class="p-2 d-flex align-items-center justify-content-between">
              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid"
              >
                <span v-if="!theme">{{ $t('page.courses.form.buttons.create') }}</span>
                <span v-else>{{ $t('components.button.labels.save') }}</span>
              </b-button>
              <b-button
                variant="flat-secondary"
                type="button"
                @click="hide"
              >
                {{ $t('components.button.labels.cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateThemeModal',
  components: {
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    theme: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
    },
    display: false,
    title: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  watch: {
    display() {
      if (!this.theme) this.form.name = null
    },
  },
  beforeMount() {
    this.title = this.$t('page.course.theme.labels.create_theme')
  },
  mounted() {
    if (this.theme) {
      this.form = { ...this.theme }
      this.title = this.$t('page.course.theme.labels.update_theme')
    }
  },
  methods: {
    async createTheme() {
      this.$http.post('/themes/create', { ...this.form, course_id: this.course.id, user_id: this.user.id })
        .then(() => {
          this.$emit('created', this.form)
          this.display = false
        })
        .catch(errors => {
          this.$refs.form.setErrors(errors)
        })
    },
    async updateTheme() {
      this.$http.put(`/themes/update?id=${this.theme.id}`, { ...this.form })
        .then(() => {
          this.$emit('updated', this.form)
          this.display = false
        })
        .catch(errors => {
          this.$refs.form.setErrors(errors)
        })
    },
    async saveTheme() {
      if (this.theme) this.updateTheme()
      else this.createTheme()
    },
    show() {
      this.display = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
