<template>
  <div class="px-1 pt-1">
    <div
      v-if="!course || currentLesson"
      class=" font-weight-bold"
    >
      <div
        v-if="!currentLesson"
        class="d-flex align-items-center justify-content-between"
      >
        <span v-if="selectedType === assignedType">Выберите карточку</span>
        <span v-else-if="selectedType === userFavoritesType">Выберите карточку</span>
        <span v-else>Выберите курс</span>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-between"
      >
        <span>Текущий урок</span>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center"
    >
      <b-link
        v-if="!noCourseCrumb"
        class="mr-50"
        :class="{'text-body': theme}"
        @click="theme ? removeTheme() : removeCourse()"
      >
        <feather-icon
          v-if="!theme"
          class="mr-50"
          icon="ChevronLeftIcon"
        />
        {{ course.name }}
      </b-link>
      <span v-else-if="!theme">
        Выберите тему
      </span>
      <b-link
        v-if="theme"
        variant="info"
        size="sm"
        @click="noCourseCrumb ? removeTheme() : ''"
      >
        <feather-icon
          class="mr-50"
          :icon="noCourseCrumb ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
        />
        {{ theme.name }}
      </b-link>
      <div class="flex-fill" />
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SidepanelBreadcrumbs',
  components: {
    BLink,
  },
  props: {
    currentLesson: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: null,
    },
    theme: {
      type: Object,
      default: null,
    },
    removeCourse: {
      type: Function,
      required: true,
    },
    removeTheme: {
      type: Function,
      required: true,
    },
    noCourseCrumb: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectedType: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      assignedType: 'course/get_course_assigned_type',
      userFavoritesType: 'course/get_course_favorites_type',
    }),
  },
}
</script>

<style scoped>

</style>
