<template>
  <course-themes
    v-if="course"
    ref="themes"
    :course="course"
    :editable="canEditCourse"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import CourseThemes from '@/components/new_courses/course/CourseThemes.vue'
import { teacherRole, userRoles } from '../../store/user'

export default {
  components: {
    CourseThemes,
  },
  data: () => ({
    course: null,
    loadingCourse: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      userType: 'course/get_course_user_type',
      readyType: 'course/get_course_ready_type',
    }),
    course_id() {
      return this.$route.params.course_id
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    courseIsReadyCourse() {
      return this.course.type === this.readyType
    },
    canEditCourse() {
      if (this.courseIsReadyCourse) {
        return this.$can('edit', 'ready-courses')
      }
      return this.course.user_id === this.user.id && this.$can('edit', 'courses')
    },
  },
  async beforeMount() {
    if (!this.course_id) this.$router.go(-1)
    else {
      this.$eventBus.$emit('page-title', 'Загрузка...')
      await this.getCourse()
      this.$eventBus.$emit('page-title', this.course.name)
      await this.$refs.themes.getThemes()
    }
  },
  methods: {
    async getCourse() {
      this.loadingCourse = true
      this.course = await this.$http.get(`/courses/view?id=${this.course_id}&expand=image,user`)
      this.loadingCourse = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-knowledge-base.scss';
</style>
