<template>
  <div class="pb-1">
    <div v-if="loading">
      <div v-if="inline">
        <div
          v-for="(skeleton, key) in 2"
          :key="key"
          class="mb-50"
        >
          <skeletor
            width="100%"
            height="25"
          />
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-wrap"
        style="margin-left: -0.5rem; margin-right: -0.5rem"
      >
        <div
          v-for="(skeleton, key) in 2"
          :key="key"
          style="flex-basis: 50%; padding-right: 0.5rem; padding-left: 0.5rem"
          class="mb-1"
        >
          <skeletor
            width="100%"
            height="150"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="!steps.length"
        class="d-flex flex-column align-items-center justify-content-center pb-2"
      >
        <p>В уроке нет карточек</p>
        <create-step-modal
          :lesson="lesson"
          :course="course"
          @created="stepAdded"
        >
          <template #activator="{show}">
            <sidepanel-add-button
              v-if="editable"
              text="Добавить карточку"
              @click="show"
            />
          </template>
        </create-step-modal>
      </div>
      <div
        v-else
        :class="{'d-flex flex-wrap': !inline}"
        :style="!inline ? 'margin-left: -0.5rem; margin-right: -0.5rem' : 'margin: 1rem'"
      >
        <create-step-modal
          :lesson="lesson"
          :course="course"
          @created="stepAdded"
        >
          <template #activator="{show}">
            <sidepanel-lesson-step-add-card
              v-if="!inline && editable"
              @click="show"
            />
          </template>
        </create-step-modal>
        <template v-for="(step, key) in steps">
          <sidepanel-lesson-step
            v-if="step.step_id || (!step.step_id && !step.exercise_id)"
            :key="step.id"
            :index="key"
            :step="step"
            :inline="inline"
            :step-selected="() => selectStep(step, key)"
            :selected="selectedStep && selectedStep.id === step.id"
          />
          <stream-exercise-card
            v-else
            :key="step.id"
            :exercise="step.exercise"
          />
        </template>

        <create-step-modal
          :lesson="lesson"
          :course="course"
          @created="stepAdded"
        >
          <template #activator="{show}">
            <sidepanel-lesson-step-add-card
              v-if="inline && editable"
              inline
              @click="show"
            />
          </template>
        </create-step-modal>
      </div>
    </div>
  </div>
</template>

<script>
import SidepanelLessonStep from '@/components/stream-courses/course/sidepanel/lesson/step/SidepanelLessonStep.vue'
import SidepanelLessonStepAddCard from '@/components/stream-courses/course/sidepanel/lesson/step/SidepanelLessonStepAddCard.vue'
import SidepanelAddButton from '@/components/stream-courses/course/sidepanel/header/SidepanelAddButton.vue'
import CreateStepModal from '@/components/new_courses/course/theme/lesson/step/CreateStepModal.vue'
import StreamExerciseCard from '../../../../video-stream/actions/StreamExerciseCard.vue'

export default {
  name: 'SidepanelLessonSteps',
  components: {
    StreamExerciseCard,
    CreateStepModal,
    SidepanelAddButton,
    SidepanelLessonStepAddCard,
    SidepanelLessonStep,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    lesson: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectedStep: {
      type: Object,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectStep(step, stepIndex) {
      this.$videoStreamEventBus.$emit('set-steps', { steps: this.steps, stepIndex })
      this.$videoStreamEventBus.$emit('select-course')
      this.$eventBus.$emit('select-step', step.step_id ? step.step : step)
    },
    async stepAdded(step) {
      this.$emit('stepAdded', step)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
