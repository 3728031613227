<template>
  <div
    style="flex-basis: 50%"
    class="mb-1"
  >
    <b-card
      no-body
      border-variant="secondary"
      class="course-card full-height mb-0 cursor-pointer"
      @click="selectExercise"
    >
      <b-card-body class="p-1">
        <p class="font-small-3 mb-0 font-weight-bolder">
          {{ exercise.name }}
        </p>
        <div class="font-small-3 text-secondary">
          Заданий: {{ exercise.exercisesContents.length }}
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StreamExerciseCard',
  components: {
    BCard,
    BCardBody,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      selectedExerciseQuestions: 'videoStream/get_exercises',
    }),
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      setExercises: 'videoStream/setLessonExercises',
    }),
    selectExercise() {
      this.$videoStreamEventBus.$emit('set-exercise', { exerciseId: this.exercise.id, exerciseIndex: 0 })
      this.setExercises(this.exercise.exercisesContents)
    },
  },
}
</script>
