<template>
  <div>
    <div
      class="d-flex align-items-center cursor-pointer"
      @click="collapseOpen = !collapseOpen"
    >
      <div class="py-1">
        {{ lesson.name }}
      </div>
      <div class="flex-fill" />
      <b-button
        class="mr-50 btn-icon"
        variant="flat-secondary"
        pill
        @click.stop="collapseOpen = !collapseOpen"
      >
        <feather-icon :icon="collapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon' " />
      </b-button>
      <b-dropdown
        v-if="editable"
        no-caret
        variant="flat-secondary"
        class="mx-0 mb-0 p-0 course-selector-list-item-button ml-auto"
        menu-class="dropdown-editable-list"
        toggle-class="p-50 rounded-pill"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
          />
        </template>

        <create-step-modal
          :lesson="lesson"
          :course="course"
          @created="stepAdded"
        >
          <template #activator="{show}">
            <b-dropdown-item-button @click.stop="show">
              <feather-icon
                icon="PlusIcon"
                class="mr-1"
              />
              <span v-if="addingStep">Добавление...</span>
              <span v-else>Добавить карточку</span>
            </b-dropdown-item-button>
          </template>
        </create-step-modal>

        <b-dropdown-divider />
        <create-lesson-modal
          :lesson="lesson"
          @updated="lsn => $emit('updated', lsn)"
        >
          <template #activator="{show}">
            <b-dropdown-item-button
              v-if="editable"
              @click.stop="show"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-1"
              />
              {{ $t('components.button.labels.edit') }}
            </b-dropdown-item-button>
          </template>
        </create-lesson-modal>
        <b-dropdown-item-button
          variant="danger"
          @click.stop="deleteLesson"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-1"
          />
          {{ $t('components.button.labels.delete') }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>

    <b-collapse
      v-model="collapseOpen"
      accordion="lessons-accordion"
      @show="loadLessonSteps"
    >
      <sidepanel-lesson-steps
        :course="course"
        :lesson="lesson"
        :steps="steps"
        :loading="loadingSteps"
        :editable="editable"
        :selected-step="selectedStep"
        :inline="inline"
        @stepAdded="stepAdded"
      />
    </b-collapse>

    <div class="thin-divider" />

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Урок ${lesson.name} и все связанные с ним этапы и упражнения будут удалены`"
    />
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItemButton, BCollapse, BButton, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import SidepanelLessonSteps from '@/components/stream-courses/course/sidepanel/lesson/SidepanelLessonSteps.vue'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import CreateLessonModal from '@/components/new_courses/course/theme/lesson/CreateLessonModal.vue'
import CreateStepModal from '@/components/new_courses/course/theme/lesson/step/CreateStepModal.vue'

export default {
  name: 'SidepanelLesson',
  components: {
    CreateStepModal,
    CreateLessonModal,
    ConfirmModal,
    SidepanelLessonSteps,
    BDropdown,
    BDropdownItemButton,
    BCollapse,
    BButton,
    BDropdownDivider,
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    lesson: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectedStep: {
      type: Object,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    assigned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      session: 'videoStream/get_session',
    }),
  },
  data: () => ({
    loadingSteps: false,
    steps: [],
    collapseOpen: false,
    addingStep: false,
  }),
  beforeDestroy() {
    this.$eventBus.$off(`lesson-${this.lesson.id}-step-removed`)
  },
  mounted() {
    this.$eventBus.$on(`lesson-${this.lesson.id}-step-removed`, step => {
      this.steps.splice(this.steps.indexOf(step), 1)
    })
  },
  methods: {
    async loadLessonSteps() {
      if (this.steps.length) return
      this.loadingSteps = true
      if (this.assigned) {
        const { items: sessionLessons } = await this.$http.get('/session-lessons/index', {
          params: {
            session_id: this.session.id,
            lesson_id: this.lesson.id,
          },
        })
        const sessionLesson = sessionLessons[0]
        if (sessionLesson) {
          const { items: sessionLessonSteps } = await this.$http.get(`/session-lesson-steps/index?session_lesson_id=${sessionLesson.id}&expand=step.stepElements.image`)
          this.steps = sessionLessonSteps.map(sessionLessonStep => sessionLessonStep.step)
        }
      } else {
        const { items } = await this.$http.get(`/lesson-steps/index?lesson_id=${this.lesson.id}&expand=stepElements.image,lessonStepsTags`)
        this.steps = items
      }
      this.loadingSteps = false
    },
    async deleteLesson() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/lessons/delete?id=${this.lesson.id},`)
              .then(() => {
                this.$emit('deleted', this.theme)
              })
          }
        })
    },
    async stepAdded(step) {
      this.steps.push(step)
      this.collapseOpen = true
      this.addingStep = false
      this.$eventBus.$emit('select-step', step)
    },
  },
}
</script>

<style scoped>

</style>
